import { createSlice } from '@reduxjs/toolkit';
import { Action } from './base';
import { sofiFormState } from '../types/sofiFormTypes';

const initialState: sofiFormState = {
  extenuado: 0,
  agotado: 0,
  exhausto: 0,
  dificultadRespiratoria: 0,
  palpitaciones: 0,
  calor: 0,
  articulacionesAgarrotadas: 0,
  entumecido: 0,
  dolorido: 0,
  apatico: 0,
  pasivo: 0,
  indiferente: 0,
  somnoliento: 0,
  durmiendome: 0,
  bostezandome: 0,
  irritable: 0,
  enojado: 0,
  furioso: 0,
};

const sofiFormSlice = createSlice({
  name: 'sofiForm',
  initialState,
  reducers: {
    update: (state: sofiFormState, action: Action) => {
      state[action.payload.name] = action.payload.value;
    },
    reset: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const sofiFormActions = sofiFormSlice.actions;
export const sofiFormReducer = sofiFormSlice.reducer;
