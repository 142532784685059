import { BodyState } from '../types/bodyTypes';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
//import { Action, baseRequestStatusReducers } from './base';

const initialState: BodyState = {
  selectedPart: '',
  menuHidden: true,
};

const BodySlice = createSlice({
  name: 'body',
  initialState,
  reducers: {
    select: (state, action: PayloadAction<string>) => {
      state.selectedPart = action.payload;
      state.menuHidden = false;
    },
    unselect: (state) => {
      state.selectedPart = '';
      state.menuHidden = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const bodyActions = BodySlice.actions;
export const bodyReducer = BodySlice.reducer;
