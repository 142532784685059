import { Business } from '../types/businessTypes';
import { createSlice } from '@reduxjs/toolkit';
import { Action } from './base';

const initialState: Business = {
  // pass
  getUsersStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const BusinessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    login: (state: Business, action: Action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    logout: () => {
      return {
        ...initialState,
      };
    },
    successGetUsers: (state: Business, action: Action) => {
      return {
        ...state,
        users: [...action.payload],
        getUsersStatus: { loading: false, success: true, error: false },
      };
    },
    loadingGetUsers: (state: Business) => {
      return {
        ...state,
        getUsersStatus: { loading: true, success: false, error: false },
      };
    },
    errorGetUsers: (state: Business) => {
      return {
        ...state,
        getUsersStatus: { loading: false, success: false, error: true },
      };
    },
  },
});

export const businessActions = BusinessSlice.actions;
export const businessReducer = BusinessSlice.reducer;
