import { createSlice } from '@reduxjs/toolkit';
import { Action, baseRequestStatusReducers } from './base';
import { FiltersState } from '../types/filterTypes';

const initialState: FiltersState = {
  filters: [],
  filteredProtocolIds: [],
  getFiltersStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const successGetFilters = (state: FiltersState, action: Action) => {
  return {
    ...state,
    filters: [
      ...action.payload.map((filter: any) => {
        return { id: +filter.id, name: filter.name, protocolIds: filter.protocol_ids, active: true };
      }),
    ],
    filteredProtocolIds: [].concat(...action.payload.map((filter: any) => filter.protocol_ids)),
    getCategoriesStatus: { loading: false, success: true, error: false },
  };
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    ...baseRequestStatusReducers('getFilters', initialState, null, successGetFilters),
    update: (state: FiltersState, action: Action) => {
      const new_filters = state.filters.map((fil) =>
        fil.id === +action.payload
          ? {
              ...fil,
              active: fil.active === false,
            }
          : {
              ...fil,
            },
      );
      const x_filter = new_filters.filter((fil) => fil.active === true);
      return {
        ...state,
        filters: new_filters,
        filteredProtocolIds: [].concat(...x_filter.map((p: any) => p.protocolIds)),
      };
    },
  },
});

export const filterActions = filterSlice.actions;
export const filterReducer = filterSlice.reducer;
