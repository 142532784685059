import { createSlice } from '@reduxjs/toolkit';
import { Action, baseRequestStatusReducers } from './base';
import { CategoriesState } from '../types/categoryTypes';

const initialState: CategoriesState = {
  categories: [],
  getCategoriesStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const successGetCategories = (state: CategoriesState, action: Action) => {
  return {
    ...state,
    categories: [
      ...action.payload.map((cat: any) => {
        return { id: cat.id, name: cat.name, protocolIds: cat.protocol_ids };
      }),
    ],
    getCategoriesStatus: { loading: false, success: true, error: false },
  };
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    ...baseRequestStatusReducers('getCategories', initialState, null, successGetCategories),
  },
});

export const categoryActions = categorySlice.actions;
export const categoryReducer = categorySlice.reducer;
