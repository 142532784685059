import React, { FC } from 'react';
import NordicTableRow from './NordicTableRow';

interface Part {
  tag: string;
  name: string;
}

interface Props {
  name: string;
  valueRange: number[];
  columnVars: Part[];
}

const NordicToggleButtonGrid: FC<Props> = (props: Props) => {
  const { name, valueRange, columnVars } = props;
  return (
    <>
      {columnVars.map((c) => (
        <NordicTableRow key={`${name}-body-${c.name}`} tag={c.tag} name={c.name} valueRange={valueRange} />
      ))}
    </>
  );
};

export default NordicToggleButtonGrid;
