import { NordicFormState } from '../types/nordicFormTypes';
import { createSlice } from '@reduxjs/toolkit';
import { Action } from './base';

const initialState: NordicFormState = {
  firstCheck: {
    cuello: false,
    hombro: false,
    brazoCodo: false,
    manoMuñeca: false,
    espaldaAlta: false,
    espaldaBaja: false,
    caderaPierna: false,
    rodilla: false,
    tobilloPie: false,
  },
  weekCheck: {
    cuello: false,
    hombro: false,
    brazoCodo: false,
    manoMuñeca: false,
    espaldaAlta: false,
    espaldaBaja: false,
    caderaPierna: false,
    rodilla: false,
    tobilloPie: false,
  },
  weekScale: {},
  lowerBackProblems: false,
  neckProblems: false,
  shouldersProblems: false,
  postNordicFormStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const NordicFormSlice = createSlice({
  name: 'nordicForm',
  initialState,
  reducers: {
    updateFirstCheck: (state: NordicFormState, action: Action) => {
      return {
        ...state,
        firstCheck: { ...action.payload },
        weekCheck: { ...initialState.weekCheck },
        weekScale: {
          cuello: 0,
          hombro: 0,
          brazoCodo: 0,
          manoMuñeca: 0,
          espaldaAlta: 0,
          espaldaBaja: 0,
          caderaPierna: 0,
          rodilla: 0,
          tobilloPie: 0,
        },
      };
    },
    updateWeekCheck: (state: NordicFormState, action: Action) => {
      return {
        ...state,
        weekCheck: { ...action.payload },
        weekScale: {
          cuello: 0,
          hombro: 0,
          brazoCodo: 0,
          manoMuñeca: 0,
          espaldaAlta: 0,
          espaldaBaja: 0,
          caderaPierna: 0,
          rodilla: 0,
          tobilloPie: 0,
        },
      };
    },
    updateWeekScale: (state: NordicFormState, action: Action) => {
      state.weekScale[action.payload.name] = action.payload.value;
    },
    updatePartDetails: (state: NordicFormState, action: Action) => {
      state[action.payload.name] = { ...action.payload.value };
    },
    resetNordicForm: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const nordicFormActions = NordicFormSlice.actions;
export const nordicFormReducer = NordicFormSlice.reducer;
